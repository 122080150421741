import userServices from '../../../../services/user'
import roleServices from '../../../../services/role'
import orgServices from '../../../../services/notisphere/organization'
import {getUserType } from '../../../../misc/auth'
import { USERS_STATUS, ACTION_TYPE, USER_TYPE } from '../../../../misc/commons'
export default {
    data() {
        return {
            item: {
                organization_id: null,
                id: null,
                first_name: null,
                last_name: null,
                email: null,
                title: null,
                phone_number: null,
                location: null,
                role_id: null,
                user_status: '',
                non_verified: false,
                site_id: []
            },
            phonenumber: '',
            users_status: USERS_STATUS,
            action_type: ACTION_TYPE,
            isCentralizedIntake: false,
            user_type: USER_TYPE,
            usertype: getUserType(),
            formType: 'INSERT',
            roleMetas: [],
            organizations: [],
            IsSiteTextboxVisible: false,
            sitesMetas: [],
            site_id: [],
            userId: null
        }
    },

    computed: {
        form() {
            if(this.formType == this.action_type.INSERT){
                return {
                    title: 'New User',
                    isDisable: false
                }
            }
            if(this.formType == this.action_type.UPDATE){
                return {
                    title: 'Update User',
                    isDisable: true
                }
            }
        }
    },
    created(){
        this.getCentralizedSettingData()
    },
    watch: {
        'item.organization_id'(val){
            if (this.formType == this.action_type.INSERT) {
                this.getRoleMeta(val)
                this.item.role_id = null
            }
            this.getCentralizedSettingData(val)
            this.getSitesMeta(val)
            var usertypes=this.organizations.filter(s=>s.id==this.item.organization_id)
            if(usertypes!=undefined && usertypes.length>0)
            {
                this.usertype=  usertypes[0]?.type
            }
        },
    },
    methods: {
        getCentralizedSettingData(orgId){
            if(orgId != undefined)
            {
                userServices.getNotisphereOrgCentralizedIntakeData(orgId).then(resp=>{
                    this.isCentralizedIntake = resp.data.data
                    if(this.isCentralizedIntake && (this.item.user_status == '' || this.item.user_status == this.users_status.NONVERIFIED)){
                        this.item.user_status = this.users_status.NONVERIFIED
                    }
                })
            }
            else
            {
                userServices.getCentralizedIntakeData().then(resp =>{
                    this.isCentralizedIntake = resp.data.data
                    if(this.isCentralizedIntake && (this.item.user_status == '' || this.item.user_status == this.users_status.NONVERIFIED)){
                        this.item.user_status = this.users_status.NONVERIFIED
                    }
                })
            }
        },
        update(val)
        {
            var role = this.roleMetas.filter(x => x.name == 'Administrator')
            if(role.length > 0 && role[0].id==val)
            {
                var selectediteid=[]
                if(this.sitesMetas.filter(s=>s.text=='Corporate')!=null)
                {
                    for (let i = 0; i < this.sitesMetas.filter(s=>s.text=='Corporate').length; i++) {
                        selectediteid.push(
                            this.sitesMetas.filter(s=>s.text=='Corporate')[i].value
                        )
                    }
                }
                this.site_id = selectediteid
                this.IsSiteTextboxVisible=true
            }
            else{
                this.IsSiteTextboxVisible=false
            }
        },
        forceRerenderInsert(organization_id) {
            this.formType = this.action_type.INSERT
            if (this._isNotisphere ) {
                this.item.organization_id = organization_id == null ? this.$store.state.orgId : organization_id
                this.fetchOrgMeta()
            } else {
                this.item.organization_id = this.$store.state.orgId
            }

            this.getSitesMeta(this.item.organization_id)
            if(!this.item.phone_number){
                this.item.phone_number = ''//'0000000000'
                this.acceptNumber()
            }
            this.$refs.modal.show()
        },
        forceRerenderUpdate(id, organization_id,organization_types,status) {
            this.userId=id
            this.formType = this.action_type.UPDATE
            if (this._isNotisphere ) {
                this.item.organization_id = organization_id == null ? this.$store.state.orgId : organization_id
                this.fetchOrgMeta()
            } else {
                this.item.organization_id = this.$store.state.orgId
            }
            this.fetchUserDetails(this.userId)
            this.getSitesMeta(organization_id)
            this.usertype= organization_types
            this.item.user_status= status
            this.$refs.modal.show()
        },
        fetchUserDetails(id,mode) {
            userServices.getUser(id).then(resp=>{
                if(!resp.error){
                    this.item = resp.data.d
                    if(this.item.status == this.users_status.NONVERIFIED && mode != this.users_status.NONVERIFIED)
                    {
                        this.item.user_status = this.users_status.NONVERIFIED
                        this.item.non_verified = true
                    }
                    if(this.item.phone_number == undefined)
                    {
                        this.phonenumber= ''
                    }
                    else
                    {
                        this.phonenumber= this.item.phone_number
                    }
                    this.acceptNumber()
                    var selectedorg=[]
                    if(resp.data.d.selectedSites!=null)
                    {
                        for (let i = 0; i < resp.data.d.selectedSites.length; i++) {
                            selectedorg.push(
                                resp.data.d.selectedSites[i].value
                            )
                        }
                    }
                    this.site_id = selectedorg
                    var role = this.roleMetas.filter(x => x.name == 'Administrator')
                    if(role.length > 0 && role[0].id==resp.data.d.role_id)
                    {
                        this.IsSiteTextboxVisible=true
                    }
                    else{
                        this.IsSiteTextboxVisible=false
                    }
                    this.getRoleMeta(this.item.organization_id)
                }
            })
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(this.item.non_verified && (this.site_id == null || this.site_id.length == 0))
            {
                this._showToast('Please select atleast one site.', { variant: 'danger', title: 'Error' })
                isValid = false
            }
            if(isValid && this.formType == this.action_type.INSERT) {
                this.item.site_id=this.site_id
                this.createUser()
            }
            if(isValid && this.formType == this.action_type.UPDATE){
                this.item.site_id=this.site_id
                this.updateUser()
            }
        },
        updateUser() {
            this.item.phone_number = this.phonenumber
            userServices.updateUser(this.item).then(resp=>{
                if(!resp.error){
                    this.$emit('onCompleted', this.action_type.UPDATE)
                    this.$refs.modal.hide()
                }
            })
        },
        createUser() {
            this.item.phone_number = this.phonenumber
            userServices.createUser(this.item).then(resp=>{
                if(!resp.error){
                    var userData = []
                    userData.emailTime=resp.data.d
                    userData.emailId=this.item.email
                    userData.type=this.action_type.INSERT
                    this.$emit('onCompleted', userData)
                    this.$refs.modal.hide()
                }
            })
        },
        getUser(id,mode) {
            userServices.getUser(id).then(resp=>{
                if(!resp.error){
                    this.item = resp.data.d
                    if(this.item.phone_number == null || this.item.phone_number == undefined)
                    {
                        this.item.phone_number = ''
                    }
                    if(this.item.phone_number == undefined)
                    {
                        this.phonenumber= ''
                    }
                    else
                    {
                        this.phonenumber= this.item.phone_number
                    }
                    if(this.item.status == this.users_status.NONVERIFIED && mode != this.users_status.NONVERIFIED)
                    {
                        this.item.user_status = this.users_status.NONVERIFIED
                        this.item.non_verified = true
                    }
                    this.acceptNumber()
                    var selectedorg=[]
                    if(resp.data.d.selectedSites!=null)
                    {
                        for (let i = 0; i < resp.data.d.selectedSites.length; i++) {
                            selectedorg.push(
                                resp.data.d.selectedSites[i].value
                            )
                        }
                    }
                    this.site_id = selectedorg
                    var role = this.roleMetas.filter(x => x.name == 'Administrator')
                    if(role.length > 0 && role[0].id==resp.data.d.role_id)
                    {
                        this.IsSiteTextboxVisible=true
                    }
                    else{
                        this.IsSiteTextboxVisible=false
                    }
                }
            })
        },
        handleCheckedValue(event) {
            if(event.target.checked){
                if(this.formType != 'UPDATE')
                {
                    var selectediteid=[]
                    if(this.sitesMetas.filter(s=>s.text=='Corporate')!=null)
                    {
                        for (let i = 0; i < this.sitesMetas.filter(s=>s.text=='Corporate').length; i++) {
                            selectediteid.push(
                                this.sitesMetas.filter(s=>s.text=='Corporate')[i].value
                            )
                        }
                    }
                    this.site_id = selectediteid
                    this.IsSiteTextboxVisible=true
                }
                this.item.non_verified = true
            }
            else{
                this.item.non_verified = false
                this.IsSiteTextboxVisible=false
            }
            this.getRoleMeta(this.item.organization_id,this.users_status.NONVERIFIED)
        },
        acceptNumber() {
            this.phonenumber =  (this.phonenumber?.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)[1] ? this.phonenumber?.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)[1] + '-' : '') + this.phonenumber?.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)[2] + (this.phonenumber?.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)[3] ? '-' + this.phonenumber?.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)[3] : '')
            this.update(this.item.role_id)
        },
        getRoleMeta(organization_id,mode) {
            roleServices.getMeta(organization_id,this.item.non_verified== undefined ? false : this.item.non_verified).then(resp=>{
                if(!resp.error){
                    if (this.formType == this.action_type.INSERT) {
                        this.roleMetas = resp.data.d.filter(x => x.allowed_creation == true)
                    }
                    if (this.formType == this.action_type.UPDATE) {
                        if (this._isNotisphere ) {
                            this.item.organization_id = organization_id == null ? this.$store.state.orgId : organization_id
                            this.fetchOrgMeta()
                        } else {
                            this.item.organization_id = this.$store.state.orgId
                        }
                        this.roleMetas = resp.data.d
                        if(mode != this.users_status.NONVERIFIED)
                        {
                            this.getUser(this.userId,mode)
                        }
                    }
                    let org= this.organizations.filter(x=>x.id==organization_id )[0]
                    if(org != null && org != undefined && org.status =='PENDING'){
                        this.roleMetas = resp.data.d.filter(x=>x.name== 'Administrator')
                    }
                }
            })
        },
        getSitesMeta(organization_id) {
            userServices.getSitesMetaforusers(organization_id).then(resp=>{
                if(!resp.error){
                    this.sitesMetas = resp.data.d
                }
            })
        },
        fetchOrgMeta() {
            orgServices.findMeta().then(resp=>{
                if(!resp.error){
                    this.organizations = resp.data.d
                    var usertypes=this.organizations.filter(s=>s.id==this.item.organization_id)
                    if(usertypes!=undefined && usertypes.length>0)
                    {
                        this.usertype=  usertypes[0].type
                    }
                    let org= this.organizations.filter(x=>x.id==this.item.organization_id )[0]
                    if(org !=null)
                    {
                        this.getCentralizedSettingData(org.id)
                    }
                    if(org != null && org != undefined && org.status =='PENDING'){
                        this.roleMetas = this.roleMetas.filter(x=>x.name== 'Administrator')
                    }
                }
            })
        }
    }
}